import axios from "axios";
import Axios from "axios";

export const percepcionCargasFamiliaApi = {
  create(data) {
    return axios.post('percepcion-cargas-familia', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => response.data)
  },
  put(id, data) {
    data.append('_method', 'PUT');
    return Axios.post('percepcion-cargas-familia/' + id, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => response.data)
  },
}
