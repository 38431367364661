<template>
  <div class="modal-form" style="width: 700px; max-width: 100%">
    <h3 class="card-title">Modificar hijo</h3>
    <form @submit.prevent="save">
      <div class="cols2">
        <pd-input
          v-model="percepcionCargasFamiliaHijo.cuil"
          id="cuil"
          label="CUIL"
          placeholder="CUIL"
          type="number"
          :required="enviado && !$v.percepcionCargasFamiliaHijo.cuil.required"
        ></pd-input>
        <pd-input
          v-model="percepcionCargasFamiliaHijo.dni"
          id="dni"
          label="DNI"
          placeholder="DNI"
          type="number"
          :required="enviado && !$v.percepcionCargasFamiliaHijo.dni.required"
        ></pd-input>
        <pd-input
          v-model="percepcionCargasFamiliaHijo.nombre"
          id="nombre"
          label="Nombre"
          placeholder="Nombre"
          :required="enviado && !$v.percepcionCargasFamiliaHijo.nombre.required"
        ></pd-input>
        <pd-input
          v-model="percepcionCargasFamiliaHijo.fecha_nacimiento"
          id="fecha_nacimiento"
          label="Fecha nacimiento"
          type="date"
          :required="enviado && !$v.percepcionCargasFamiliaHijo.fecha_nacimiento.required"
        ></pd-input>
        <pd-input
          v-model="percepcionCargasFamiliaHijo.lugar"
          id="lugar"
          label="Lugar"
          placeholder="Lugar"

          :required="enviado && !$v.percepcionCargasFamiliaHijo.lugar.required"
        ></pd-input>
        <div class="form-group">
          <label class="form-label">Discapacidad</label>
          <div class="form-control radio-container">
            <input type="checkbox" id="discapacidad" :value="true" v-model="percepcionCargasFamiliaHijo.discapacidad"/>
            <label style="padding-left:.5rem;" for="discapacidad">¿Tiene discapacidad?</label>
          </div>
        </div>

        <div class="form-group d-flex align-end"
        >
          <label class="d-flex left btn-flat-dark text-small form-control"
                 style="padding: .5rem"
                 for="acta_nacimiento">
            <icon feather="edit" style="height:1rem"/>
            <span v-if="percepcionCargasFamiliaHijo.acta_nacimiento" class="px-1"
                  style="margin-top: .25rem">{{ percepcionCargasFamiliaHijo.acta_nacimiento_original_name }}</span>
            <span v-else class="px-1"
                  style="margin-top: .25rem">Subir acta de nacimiento</span>
            <input
              type="file"
              id="acta_nacimiento"
              @change="changeActaNacimiento"
              style="display: none"
            />
          </label>
        </div>

        <div class="form-group d-flex align-end"
        >
          <label class="d-flex left btn-flat-dark text-small form-control"
                 style="padding: .5rem"
                 for="certificado_alumno_regular">
            <icon feather="edit" style="height:1rem"/>
            <span v-if="percepcionCargasFamiliaHijo.certificado_alumno_regular" class="px-1"
                  style="margin-top: .25rem">{{ percepcionCargasFamiliaHijo.certificado_alumno_regular_original_name }}</span>
            <span v-else class="px-1"
                  style="margin-top: .25rem">Subir certificado alumno regular</span>
            <input
              type="file"
              id="certificado_alumno_regular"
              @change="changeAlumnoRegular"
              style="display: none"
            />
          </label>
        </div>

        <div class="form-group d-flex align-end"
             v-if="percepcionCargasFamiliaHijo.discapacidad"
        >
          <label class="d-flex left btn-flat-dark text-small form-control"
                 :class="{'is-invalid': enviado && $v.percepcionCargasFamiliaHijo.certificado_discapacidad.$invalid}"
                 style="padding: .5rem"
                 for="certificado_discapacidad">
            <icon feather="edit" style="height:1rem"/>
            <span v-if="percepcionCargasFamiliaHijo.certificado_discapacidad" class="px-1"
                  style="margin-top: .25rem">{{ percepcionCargasFamiliaHijo.certificado_discapacidad_original_name }}</span>
            <span v-else class="px-1"
                  style="margin-top: .25rem">Subir certificado de discapacidad</span>
            <input
              type="file"
              id="certificado_discapacidad"
              @change="changeCertificadoDiscapacidad"
              style="display: none"
            />
          </label>
          <small class="form-message text-danger"
                 v-if="enviado && $v.percepcionCargasFamiliaHijo.certificado_discapacidad.$invalid"
          >Éste campo es obligatorio.</small
          >
        </div>

      </div>
      <button class="btn btn-right btn-primary mt-1">Modificar hijo</button>
    </form>
  </div>
</template>
<script>
import {
  percepcionCargasFamiliaHijoForm, percepcionCargasFamiliaHijoValidation
} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionCargasFamiliaHijoForm";

export default {
  name: "EditPercepcionCargasFamiliaHijos",
  props: {
    selectedHijo: {
      type: {},
      required: true
    }
  },
  data() {
    return {
      percepcionCargasFamiliaHijo: percepcionCargasFamiliaHijoForm(this.selectedHijo),
      enviado: false
    }
  },
  validations: {
    percepcionCargasFamiliaHijo: percepcionCargasFamiliaHijoValidation()
  },
  methods: {
    changeActaNacimiento(e) {
      this.percepcionCargasFamiliaHijo.acta_nacimiento = e.target.files[0]
    },
    changeAlumnoRegular(e) {
      this.percepcionCargasFamiliaHijo.certificado_alumno_regular = e.target.files[0]
    },
    changeCertificadoDiscapacidad(e) {
      this.percepcionCargasFamiliaHijo.certificado_discapacidad = e.target.files[0]
    },
    save() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.$emit('saved', this.percepcionCargasFamiliaHijo)
    }
  }
}
</script>

<style scoped>

</style>
