import {required, requiredIf} from "vuelidate/lib/validators";

export const percepcionCargasFamiliaHijoForm = (hijo = null) => {
  return {
    id: hijo?.id ?? null,
    percepcion_cargas_familia_id: hijo?.percepcion_cargas_familia_id ?? null,
    cuil: hijo?.cuil ?? null,
    nombre: hijo?.nombre ?? null,
    fecha_nacimiento: hijo?.fecha_nacimiento ?? null,
    lugar: hijo?.lugar ?? null,
    dni: hijo?.dni ?? null,
    discapacidad: hijo?.discapacidad ?? null,
    acta_nacimiento: hijo?.acta_nacimiento,
    certificado_alumno_regular: hijo?.certificado_alumno_regular,
    certificado_discapacidad: hijo?.certificado_discapacidad,
    acta_nacimiento_original_name: hijo?.acta_nacimiento_original_name ?? 'Modificar acta nacimiento',
    certificado_alumno_regular_original_name: hijo?.certificado_alumno_regular_original_name ?? 'Modificar certificado alumno regular',
    certificado_discapacidad_original_name: hijo?.certificado_discapacidad_original_name ?? 'Modificar certificado discapacidad',
  }
}

export const percepcionCargasFamiliaHijoValidation = () => {
  return {
    cuil: {required},
    nombre: {required},
    fecha_nacimiento: {required},
    lugar: {required},
    dni: {required},
    acta_nacimiento: {},
    certificado_discapacidad: {
      required: requiredIf(function (percepcionCargasFamiliaHijo) {
        return percepcionCargasFamiliaHijo.discapacidad
      })
    },
  }
}
