import {required, requiredIf} from "vuelidate/lib/validators";
import {
  percepcionCargasFamiliaHijoForm
} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionCargasFamiliaHijoForm";

export const percepcionCargasFamiliaForm = (user = null, asignacionFamiliar = null) => {
  const percepcionCargasFamilia = {
    year: asignacionFamiliar?.percepcion_cargas_familia?.year ?? new Date().getFullYear(),
    nombre_empleado: asignacionFamiliar?.percepcion_cargas_familia?.nombre_empleado ?? user?.persona.nombre,
    cuil_empleado: asignacionFamiliar?.percepcion_cargas_familia?.cuil_empleado ?? user?.persona.cuil,
    dni_empleado: asignacionFamiliar?.percepcion_cargas_familia?.dni_empleado ?? user ? user.persona.cuil.substring(2, 10) : null,

    nombre_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.nombre_progenitor,
    cuil_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cuil_progenitor,
    dni_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.dni_progenitor,
    fecha_nacimiento_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.fecha_nacimiento_progenitor,
    nacionalidad_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.nacionalidad_progenitor,
    calle_domicilio_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.calle_domicilio_progenitor,
    numero_domicilio_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.numero_domicili_progenitor,
    piso_domicilio_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.piso_domicilio_progenitor,
    dpto_domicilio_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.dpto_domicilio_progenitor,
    localidad_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.localidad_progenitor,
    provincia_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.provincia_progenitor,
    codigo_postal_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.codigo_postal_progenitor,
    autorizacion_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.autorizacion_progenitor,
    autorizacion_progenitor_original_name: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.autorizacion_progenitor_original_name ?? 'Modificar autorización otro progenitor',
    tiene_empleo_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.tiene_empleo_progenitor === 1  ? true : false,

    otro_empleo: asignacionFamiliar?.percepcion_cargas_familia?.otro_empleo === 1  ? true : false,
    otro_empleo_recibo_sueldo: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.recibo_sueldo,
    otro_empleo_recibo_sueldo_original_name: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.recibo_sueldo_original_name ?? 'Modificar recibo de sueldo',
    otro_empleo_razon_social_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.razon_social_empleador,
    otro_empleo_cuit_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.cuit_empleador,
    otro_empleo_fecha_ingreso: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.fecha_ingreso,
    otro_empleo_calle_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.calle_domicilio_empleador,
    otro_empleo_numero_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.numero_domicilio_empleador,
    otro_empleo_piso_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.piso_domicilio_empleador,
    otro_empleo_dpto_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.dpto_domicilio_empleador,
    otro_empleo_codigo_postal_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.codigo_postal_empleador,
    otro_empleo_localidad_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.localidad_empleador,
    otro_empleo_provincia_empleador: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.provincia_empleador,

    otro_empleo_percibe_asignaciones: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.percibe_asignaciones === 1  ? true : false,
    otro_empleo_certificado_no_percepcion: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.certificado_no_percepcion,
    otro_empleo_certificado_no_percepcion_original_name: asignacionFamiliar?.percepcion_cargas_familia?.cargas_familia_otro_empleo?.certificado_no_percepcion_original_name ?? 'Modificar certificado de no percepción',

    otro_empleo_trabajador_independiente: asignacionFamiliar?.percepcion_cargas_familia?.trabajador_independiente === 1  ? true : false,
    otro_empleo_ultima_ddjj: asignacionFamiliar?.percepcion_cargas_familia?.ultima_ddjj,
    otro_empleo_ultima_ddjj_original_name: asignacionFamiliar?.percepcion_cargas_familia?.ultima_ddjj_original_name ?? 'Modificar última ddjj',

    empleo_progenitor_recibo_sueldo: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.recibo_sueldo,
    empleo_progenitor_recibo_sueldo_original_name: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.recibo_sueldo_original_name ?? 'Modificar recibo de sueldo',
    empleo_progenitor_razon_social_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.razon_social_empleador,
    empleo_progenitor_cuit_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.cuit_empleador,
    empleo_progenitor_calle_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.calle_domicilio_empleador,
    empleo_progenitor_numero_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.numero_domicilio_empleador,
    empleo_progenitor_piso_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.piso_domicilio_empleador,
    empleo_progenitor_dpto_domicilio_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.dpto_domicilio_empleador,
    empleo_progenitor_codigo_postal_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.codigo_postal_empleador,
    empleo_progenitor_localidad_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.localidad_empleador,
    empleo_progenitor_provincia_empleador: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.provincia_empleador,
    empleo_progenitor_percibe_asignaciones: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.percibe_asignaciones === 1  ? true : false,
    empleo_progenitor_certificado_no_percepcion: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.certificado_no_percepcion,
    empleo_progenitor_certificado_no_percepcion_original_name: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.cargas_familia_empleo_progenitor?.certificado_no_percepcion_original_name ?? 'Modificar certificado de no percepción',
    empleo_progenitor_trabajador_independiente: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.trabajador_independiente === 1 ? true : false,
    empleo_progenitor_ultima_ddjj: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.ultima_ddjj,
    empleo_progenitor_ultima_ddjj_original_name: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.ultima_ddjj_original_name ?? 'Modificar última ddjj',
    causas_no_percepcion_progenitor: asignacionFamiliar?.percepcion_cargas_familia?.percepcion_cargas_familia_progenitor?.causas_no_percepcion_progenitor,
    hijos: []
  }

  if (asignacionFamiliar?.percepcion_cargas_familia?.hijos.length) {
    for (const hijo of asignacionFamiliar.percepcion_cargas_familia.hijos) {
      percepcionCargasFamilia.hijos.push(percepcionCargasFamiliaHijoForm(hijo))
    }
  }

  return percepcionCargasFamilia
}

export const percepcionCargsFamiliaValidation = () => {
  return {
    year: {required},
    nombre_empleado: {required},
    cuil_empleado: {required},
    dni_empleado: {required},
    nombre_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    cuil_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    dni_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    fecha_nacimiento_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    nacionalidad_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    calle_domicilio_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    numero_domicilio_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    localidad_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    provincia_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    codigo_postal_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    tiene_empleo_progenitor: {},
    otro_empleo: {},
    otro_empleo_recibo_sueldo: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_razon_social_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_cuit_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_fecha_ingreso: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_calle_domicilio_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_numero_domicilio_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_codigo_postal_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_localidad_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_provincia_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo
      })
    },
    otro_empleo_percibe_asignaciones: {},
    otro_empleo_certificado_no_percepcion: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo && !percepcionCargasFamilia.otro_empleo_percibe_asignaciones
      })
    },
    otro_empleo_trabajador_independiente: {},
    otro_empleo_ultima_ddjj: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.otro_empleo_trabajador_independiente
      })
    },
    empleo_progenitor_recibo_sueldo: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_razon_social_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_cuit_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_calle_domicilio_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_numero_domicilio_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_codigo_postal_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_localidad_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_provincia_empleador: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor
      })
    },
    empleo_progenitor_percibe_asignaciones: false,
    empleo_progenitor_certificado_no_percepcion: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.tiene_empleo_progenitor &&
          !percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones
      })
    },
    empleo_progenitor_trabajador_independiente: false,
    empleo_progenitor_ultima_ddjj: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return percepcionCargasFamilia.empleo_progenitor_trabajador_independiente
      })
    },
    autorizacion_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo &&
          !percepcionCargasFamilia.otro_empleo_percibe_asignaciones &&
          !percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones
      })
    },
    causas_no_percepcion_progenitor: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo &&
          !percepcionCargasFamilia.otro_empleo_percibe_asignaciones &&
          !percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones
      })
    },
    hijos: {
      required: requiredIf(function (percepcionCargasFamilia) {
        return !percepcionCargasFamilia.otro_empleo_percibe_asignaciones &&
          !percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones
      })
    }
  }
}

export const convertCargasFamiliaToFormData = (percepcionCargasFamilia) => {
  const formData = new FormData()
  formData.append('year', percepcionCargasFamilia.year)
  formData.append('nombre_empleado', percepcionCargasFamilia.nombre_empleado)
  formData.append('cuil_empleado', percepcionCargasFamilia.cuil_empleado)
  formData.append('dni_empleado', percepcionCargasFamilia.dni_empleado)
  formData.append('nombre_progenitor', percepcionCargasFamilia.nombre_progenitor)
  formData.append('cuil_progenitor', percepcionCargasFamilia.cuil_progenitor)
  formData.append('dni_progenitor', percepcionCargasFamilia.dni_progenitor)
  formData.append('fecha_nacimiento_progenitor', percepcionCargasFamilia.fecha_nacimiento_progenitor)
  formData.append('nacionalidad_progenitor', percepcionCargasFamilia.nacionalidad_progenitor)
  formData.append('calle_domicilio_progenitor', percepcionCargasFamilia.calle_domicilio_progenitor)
  formData.append('numero_domicilio_progenitor', percepcionCargasFamilia.numero_domicilio_progenitor)
  formData.append('piso_domicilio_progenitor', percepcionCargasFamilia.piso_domicilio_progenitor)
  formData.append('dpto_domicilio_progenitor', percepcionCargasFamilia.dpto_domicilio_progenitor)
  formData.append('localidad_progenitor', percepcionCargasFamilia.localidad_progenitor)
  formData.append('provincia_progenitor', percepcionCargasFamilia.provincia_progenitor)
  formData.append('codigo_postal_progenitor', percepcionCargasFamilia.codigo_postal_progenitor)
  formData.append('causas_no_percepcion_progenitor', percepcionCargasFamilia.causas_no_percepcion_progenitor)
  formData.append('tiene_empleo_progenitor', percepcionCargasFamilia.tiene_empleo_progenitor)
  formData.append('autorizacion_progenitor', percepcionCargasFamilia.autorizacion_progenitor)
  formData.append('otro_empleo', percepcionCargasFamilia.otro_empleo)
  formData.append('otro_empleo_recibo_sueldo', percepcionCargasFamilia.otro_empleo_recibo_sueldo)
  formData.append('otro_empleo_razon_social_empleador', percepcionCargasFamilia.otro_empleo_razon_social_empleador)
  formData.append('otro_empleo_cuit_empleador', percepcionCargasFamilia.otro_empleo_cuit_empleador)
  formData.append('otro_empleo_fecha_ingreso', percepcionCargasFamilia.otro_empleo_fecha_ingreso)
  formData.append('otro_empleo_calle_domicilio_empleador', percepcionCargasFamilia.otro_empleo_calle_domicilio_empleador)
  formData.append('otro_empleo_numero_domicilio_empleador', percepcionCargasFamilia.otro_empleo_numero_domicilio_empleador)
  formData.append('otro_empleo_piso_domicilio_empleador', percepcionCargasFamilia.otro_empleo_piso_domicilio_empleador)
  formData.append('otro_empleo_dpto_domicilio_empleador', percepcionCargasFamilia.otro_empleo_dpto_domicilio_empleador)
  formData.append('otro_empleo_codigo_postal_empleador', percepcionCargasFamilia.otro_empleo_codigo_postal_empleador)
  formData.append('otro_empleo_localidad_empleador', percepcionCargasFamilia.otro_empleo_localidad_empleador)
  formData.append('otro_empleo_provincia_empleador', percepcionCargasFamilia.otro_empleo_provincia_empleador)
  formData.append('otro_empleo_percibe_asignaciones', percepcionCargasFamilia.otro_empleo_percibe_asignaciones)
  formData.append('otro_empleo_certificado_no_percepcion', percepcionCargasFamilia.otro_empleo_certificado_no_percepcion)
  formData.append('otro_empleo_trabajador_independiente', percepcionCargasFamilia.otro_empleo_trabajador_independiente)
  formData.append('otro_empleo_ultima_ddjj', percepcionCargasFamilia.otro_empleo_ultima_ddjj)
  formData.append('empleo_progenitor_razon_social_empleador', percepcionCargasFamilia.empleo_progenitor_razon_social_empleador)
  formData.append('empleo_progenitor_recibo_sueldo', percepcionCargasFamilia.empleo_progenitor_recibo_sueldo)
  formData.append('empleo_progenitor_cuit_empleador', percepcionCargasFamilia.empleo_progenitor_cuit_empleador)
  formData.append('empleo_progenitor_calle_domicilio_empleador', percepcionCargasFamilia.empleo_progenitor_calle_domicilio_empleador)
  formData.append('empleo_progenitor_numero_domicilio_empleador', percepcionCargasFamilia.empleo_progenitor_numero_domicilio_empleador)
  formData.append('empleo_progenitor_piso_domicilio_empleador', percepcionCargasFamilia.empleo_progenitor_piso_domicilio_empleador)
  formData.append('empleo_progenitor_dpto_domicilio_empleador', percepcionCargasFamilia.empleo_progenitor_dpto_domicilio_empleador)
  formData.append('empleo_progenitor_codigo_postal_empleador', percepcionCargasFamilia.empleo_progenitor_codigo_postal_empleador)
  formData.append('empleo_progenitor_localidad_empleador', percepcionCargasFamilia.empleo_progenitor_localidad_empleador)
  formData.append('empleo_progenitor_provincia_empleador', percepcionCargasFamilia.empleo_progenitor_provincia_empleador)
  formData.append('empleo_progenitor_percibe_asignaciones', percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones)
  formData.append('empleo_progenitor_certificado_no_percepcion', percepcionCargasFamilia.empleo_progenitor_certificado_no_percepcion)
  formData.append('empleo_progenitor_trabajador_independiente', percepcionCargasFamilia.empleo_progenitor_trabajador_independiente)
  formData.append('empleo_progenitor_ultima_ddjj', percepcionCargasFamilia.empleo_progenitor_ultima_ddjj)

  for (const hijo of percepcionCargasFamilia.hijos) {
    formData.append('hijos[' + hijo.cuil + '][cuil]', hijo.cuil)
    formData.append('hijos[' + hijo.cuil + '][dni]', hijo.dni)
    formData.append('hijos[' + hijo.cuil + '][nombre]', hijo.nombre)
    formData.append('hijos[' + hijo.cuil + '][fecha_nacimiento]', hijo.fecha_nacimiento)
    formData.append('hijos[' + hijo.cuil + '][lugar]', hijo.lugar)
    formData.append('hijos[' + hijo.cuil + '][discapacidad]', hijo.discapacidad)
    formData.append('hijos[' + hijo.cuil + '][acta_nacimiento]', hijo.acta_nacimiento)
    formData.append('hijos[' + hijo.cuil + '][certificado_alumno_regular]', hijo.certificado_alumno_regular)
    formData.append('hijos[' + hijo.cuil + '][certificado_discapacidad]', hijo.certificado_discapacidad)
  }
  return formData;
}
